





















































import {Component, Prop, Vue} from "vue-property-decorator";
import Blog from "@/models/Blog";
import {format} from "date-fns";
import {es} from "date-fns/locale";

@Component
export default class BlogDialogComponent extends Vue {
    @Prop() readonly blog!: Blog
    @Prop() readonly dialog!: boolean
    @Prop() readonly switchDialog!: any


    close() {
        this.switchDialog(this.blog)
    }

    date(createdAt: string) {
        return format(new Date(createdAt), "dd $ MMMM, YYY", {locale: es})
    }
}
