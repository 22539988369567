














import {Component, Prop, Vue} from "vue-property-decorator";
import Blog from "@/models/Blog";
import { format } from "date-fns";
import { es } from "date-fns/locale";

@Component
export default class BlogPreviewComponent extends Vue {
    @Prop() readonly blog!: Blog



    created() {
        console.log(this.blog)
    }

    date(createdAt: string) {
        return format(new Date(createdAt), "dd $ MMMM, YYY", {locale: es})
    }
}
