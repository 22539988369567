









import {Component, Vue} from "vue-property-decorator";
import Slider from "@/models/Slider";
import SliderService from "@/services/SliderService";

@Component
export default class CarouselComponent extends Vue {
    slides: Slider[] = []


    created() {
        SliderService.getSliders(this, this.slides)
    }

    goingTo(dir : string) {
        if(dir) {
            if (dir.includes("http", 0)) window.open(dir, "_blank")
            else this.$router.push(dir)
        }
    }
}
