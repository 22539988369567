

















































import {Component, Vue, Watch} from 'vue-property-decorator';
import CarouselComponent from "@/components/CarouselComponent.vue";
import Blog from "@/models/Blog";
import BlogService from "@/services/BlogService";
import BlogPreviewComponent from "@/components/BlogPreviewComponent.vue";
import BlogDialogComponent from "@/components/BlogDialogComponent.vue";
import Options from "@/models/vue/Options";

@Component({components: {CarouselComponent, BlogPreviewComponent, BlogDialogComponent}})
export default class HomeView extends Vue {
    loading: boolean = false
    blogs: Blog[] = []
    dialog: boolean = false
    blog: Blog = new Blog()
    options: Options = new Options()
    page: number = 1
    pageCount: number = 0
    itemsPerPage: number = 4
    totalItems: number = 0


    @Watch("options")
    watchOptions() {
        BlogService.getBlogs(this, this.blogs, this.page - 1, this.itemsPerPage, null)
    }

    created() {
        this.watchOptions()
    }

    switchDialog(blog: Blog) {
        this.blog = blog
        this.dialog ? this.dialog = false : this.dialog = true
    }

}
